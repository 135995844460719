import { AttentionAlert } from "../../components/Alert";
import { Info } from "../../libs/logger";
import { MAX_DEVICE_ERROR_RETRY, convertTimestampToTime } from "../../libs/utils";

export const currentTsRoutine = (_this, ts) => {
    let currentTime = Math.floor(ts / 1000);
    let startTimeTS = _this.state?.examProfile?.start_time_ts;
    let endTimeTS = _this.state?.examProfile?.end_time_ts;

    let faceValidationStatus = _this.state?.participantProfile?.validation_status === 1 || _this.state?.participantProfile?.validation_status === 3;
    // Debug("VALIDATION STATUS = ", _this.state.participantProfile.validation_status)
    let cameraOn = _this.state?.userMediaStream;
    let screenOn = _this.state?.displayMediaStream;

    // Untuk memulai rekaman ujian peserta
    if (startTimeTS !== null && currentTime >= startTimeTS) {
        _this.record = true;
    }

    // errorCaptureStatus checker
    const ec = _this.state.errorCaptureStatus;
    let usingErrorCaptureScenario = false;
    if (ec.face >= MAX_DEVICE_ERROR_RETRY || ec.screen >= MAX_DEVICE_ERROR_RETRY || ec.webrtc >= MAX_DEVICE_ERROR_RETRY) {
        usingErrorCaptureScenario = true;
    }

    //Status untuk validation button
    // go to exam here
    // Otomatis ketika syarat terpenuhi && Camera screen monitor sudah di share
    // Info(_this.state.errorCaptureStatus, ", faceValidationStatus=", faceValidationStatus);
    if (
        // (_this.props.mode !== "mobile" && faceValidationStatus && usingErrorCaptureScenario && _this.state.participantProfile.validation_status === 3)
        // ||
        (_this.props.mode !== "mobile" && faceValidationStatus && cameraOn !== null &&
            screenOn !== null &&
            _this.state.examProfile.direct_exam === 1
            && _this.state.numberOfScreen === 1
        )
        ||
        (
            _this.props.mode !== "mobile" && faceValidationStatus && currentTime >= startTimeTS &&
            cameraOn !== null && screenOn !== null
            && _this.state.numberOfScreen === 1
        )
        ||
        (
            _this.props.mode === "mobile" && faceValidationStatus && cameraOn !== null && _this.state.examProfile.direct_exam === 1)
        ||
        (
            _this.props.mode === "mobile" && faceValidationStatus && currentTime >= startTimeTS && cameraOn !== null
        )
    ) {
        clearInterval(_this.sendImageInterval);

        _this.setState({ initExam: false })

        if (_this.state.examProfile.useExtension === true) {
            _this.CE.setQuizUrl(_this.quizActivity.host);
        }


        _this.firstTimeValidation = false
    } else {
        // Debug("belum terpenuhi ", currentTime, startTimeTS,_this.props.mode , faceValidationStatus);
    }

    // untuk auto logout
    if (currentTime >= endTimeTS) {
        const confirmed = AttentionAlert("Sesi Ujian telah berakhir, anda akan logout secara otomatis.", true);

        if (confirmed) {
            _this._action.onLogout();
        }
    }

    if (_this.state.initExam === false && _this.state.numberOfScreen > 1) {
        AttentionAlert("Jumlah layar terhubung ke device anda ada lebih dari satu. Anda hanya diperbolehkan untuk menggunakan layar utama saja. Putuskan layar tambahan anda.")
        _this.setState({ initExam: true });
        _this._preparation.recoverVideoForPreparation(_this);
    }

    //Menuju Waktu Ujian
    const examCountDown = startTimeTS - currentTime;
    const daysECD = Math.floor(examCountDown / (24 * 60 * 60))
    const hoursECD = Math.floor((examCountDown % (24 * 60 * 60)) / (60 * 60));
    const minutesECD = Math.floor((examCountDown % (60 * 60)) / 60);
    const secondsECD = Math.floor(examCountDown % 60);

    const ecd = {
        days: daysECD,
        hours: hoursECD > 0 ? hoursECD : 0,
        minutes: minutesECD >= 0 ? minutesECD : 0,
        seconds: secondsECD >= 0 ? secondsECD : 0,
    }

    //Ujian Sedang Berlangsung
    // const examTimes = (_this.state?.examProfile?.end_time_ts * 1000) - ts;
    const examTimes = endTimeTS - currentTime;
    const hoursET = Math.floor((examTimes % (24 * 60 * 60)) / (60 * 60));
    const minutesET = Math.floor((examTimes % (60 * 60)) / 60);
    const secondsET = Math.floor(examTimes % 60);

    const et = {
        hours: hoursET >= 0 ? hoursET : 0,
        minutes: minutesET >= 0 ? minutesET : 0,
        seconds: secondsET >= 0 ? secondsET : 0,
    }
    _this.setState({ examTimes: et, examCountDown: ecd });

    if (startTimeTS > currentTime) {
        _this.setState({
            displayTimer: "menuju waktu ujian",
            examStage: {
                // title: "Menuju Waktu Ujian",
                // time: `${_this.state?.examCountDown?.days} Hari ${formatTime(_this.state?.examCountDown?.hours)}:${formatTime(_this.state?.examCountDown?.minutes)}:${formatTime(_this.state?.examCountDown?.seconds)}`
                time: `${convertTimestampToTime(ts)}`
            }
        })
    } else if (endTimeTS > currentTime) {
        _this.setState({
            displayTimer: "ujian",
            examStage: {
                time: `${convertTimestampToTime(ts)}`
            }
        })
    } else {
        _this.setState({
            displayTimer: "selesai",
            examStage: {
                time: `${convertTimestampToTime(ts)}`
            }
        })
    }
}