import { fetchDetailParticipant } from "./Action";
import classes from "./Participant.module.scss"
import star from "../../../assets/imgs/staryellow.png";
import flag from "../../../assets/imgs/flagcolor.png";
import newmessage from "../../../assets/imgs/newmessage.png";

export const pushToElement = (_this, par) => {
    const isOnline = (par.status === 'online')
    // const isValid = (par.validation_status === 1);
    const isValid = par.validation_status;
    const nml = _this.props.newMessageState;
    return <div
        key={par.id}
    >
        <div
            id={par.uuid}
            className={[
                classes.participant_c,
                _this.state.chosen.uuid === par.uuid ? classes.chosen : null
            ].join(" ")}
            onClick={() => {
                _this.setState({
                    isloading: true,
                    chosen: {
                        id: par.id,
                        uuid: par.uuid
                    },
                });
                if (_this.state.chosenWindow === "chat") {
                    _this.props.onChatPageEnter(par.id)
                }

                fetchDetailParticipant(_this, par.uuid);
            }}
        >
            <img alt="img" width={"50px"} src={par.img} />
            <div>
                <span className={classes.id}>{par.id}</span>
                <span className={classes.name}>{par.name}</span>
                {par.chat && <span className={classes.chat}>{par.chat}</span>}
                <div className={classes.info}>
                    {isOnline ?
                        <div className={classes.online}>online</div>
                        : <div className={classes.offline}>offline</div>
                    }
                    {isValid === 2 ?
                        <div className={classes.blocked}>blocked</div>
                        :
                        isValid === 1 ?
                            <div className={classes.online}>valid</div>
                            : <div className={classes.offline}>invalid</div>
                    }
                    {nml.indexOf(par.id) > -1 && <div>
                        <img
                            height={"30px"}
                            alt="newmessage" src={newmessage} />
                    </div>}
                    {/* <div className={classes.star}>
                        <img alt="star" src={star} />
                    </div>
                    <div className={classes.flag}>
                        <img alt="flag" src={flag} />
                    </div> */}
                </div>
            </div>
        </div>
        {/* <span style={{ color: "white" }}>
            {par.uuid}
        </span> */}
        <div className={classes.separator}></div>
    </div>
}

export const renderParticipant = (_this) => {
    let el = [];

    for (let p in _this.participants) {
        if (_this.searchRef.current !== null && _this.searchRef.current.value !== "") {
            const t = _this.searchRef.current.value.toUpperCase();
            if (p.toUpperCase().includes(t) === false && _this.participants[p].name.toUpperCase().includes(t) === false) {
                continue;
            }
        }
        const par = _this.participants[p];
        const isOnline = (par.status === 'online')
        const isValid = (par.validation_status === 1);
        const isBlocked = (par.validation_status === 2);
        if (_this.state.chosenMenu === 'online') {
            if (isOnline === false) {
                continue;
            }
        } else if (_this.state.chosenMenu === 'valid') {
            if (isValid === false || isBlocked === true) {
                continue;
            }
        } else if (_this.state.chosenMenu === 'invalid') {
            if (isValid === true || isBlocked === true) {
                continue;
            }
        } else if ( _this.state.chosenMenu === "blocked") {
            if ( isBlocked === false ) {
                continue;
            }
        }
        el.push(pushToElement(_this, par))
    }
    return <div className={[classes.participant, classes.ascroll].join(" ")}>
        {el}
    </div>
}
