import React, { Component } from 'react';
import PropTypes from "prop-types";
import ListeningAudio from '../assets/audio/LISTENING.mp3';
import classes from './AudioPlayer.module.scss';
import { Rnd } from 'react-rnd';
import closeIcon from '../assets/imgs/close.png';
import info from '../assets/imgs/info.png';
import { AttentionAlert } from './Alert';

class AudioPlayer extends Component {
    constructor(props) {
        super(props);
        this.audioRef = React.createRef();
        this.buttonRef = React.createRef();

        this.state = {
            isPlayedOnce: false,
            currentTime: 0,
            duration: 0,
            showAudioPlayer: false,
            audioPosition: { x: 0, y: 0 },
            audioList: [],
            selectedAudio: null,
            isAudioValid: true,
        };
        this.id = props.id;
        this.examProfile = props.examProfile;
        this.savedTime = null;
    }

    componentDidMount() {
        if (this.state.showAudioPlayer) {
            this.checkLastDuration();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // if (this.state.isPlayedOnce && !prevState.isPlayedOnce) {
        //     this.startSavingCurrentTime();
        // }

        // Validate the audio whenever selectedAudio changes
        if (prevState.selectedAudio !== this.state.selectedAudio) {
            this.checkAudioValidity();
        }
    }

    componentWillUnmount() {
        this.stopSavingCurrentTime();
        if (this.audioRef.current) {
            this.audioRef.current.removeEventListener("loadedmetadata", this.setAudioDuration);
        }
    }


    checkLastDuration = () => {
        let adjustedTime = this.savedTime || 0; // Jika tidak ada savedTime, mulai dari 0
        // const backTime = 2;

        // if (this.savedTime && this.state.duration !== 0 && adjustedTime > 5 && (this.state.duration - adjustedTime) > 1) {
        //     adjustedTime -= backTime;
        // }

        this.setState({ currentTime: adjustedTime }, () => {
            if (this.audioRef.current) {
                this.audioRef.current.currentTime = this.state.currentTime;
            }
        });

        if (this.audioRef.current) {
            this.audioRef.current.addEventListener("loadedmetadata", this.setAudioDuration);
        }
    };

    checkAudioValidity = () => {
        const { selectedAudio } = this.state;
        this.savedTime = localStorage.getItem((`${this.examProfile.title}_${this.id}_${this.state.selectedAudio?.name}`));

        if (selectedAudio && selectedAudio.url) {
            const audio = new Audio(selectedAudio.url);
            audio.addEventListener('loadedmetadata', () => {
                if (audio.duration > 0) {

                    // console.log("RESULT : ", this.savedTime >= Math.floor(audio.duration))

                    if (this.savedTime >= Math.floor(audio.duration)) {
                        this.setState({ isAudioValid: false, duration: audio.duration });
                    } else {
                        this.setState({ isAudioValid: true, duration: audio.duration });
                    }

                } else {
                    // console.log('Audio tidak valid (durasi tidak ditemukan)');
                    this.setState({ isAudioValid: false, duration: 0 });
                }
            });

            audio.addEventListener('error', () => {
                // console.log('Terjadi error saat memuat audio. Audio tidak valid.');
                this.setState({ isAudioValid: false, duration: 0 });
            });
        }
    }

    setAudioDuration = () => {
        if (this.audioRef.current) {
            this.setState({ duration: this.audioRef.current.duration });
        }
    };

    startSavingCurrentTime = () => {
        this.timeInterval = setInterval(() => {
            const currentTime = this.audioRef?.current?.currentTime;

            if (currentTime) {
                localStorage.setItem(`${this.examProfile.title}_${this.id}_${this.state.selectedAudio?.name}`, currentTime)
                this.setState({ currentTime });
            }

        }, 1000);
    };

    stopSavingCurrentTime = () => {
        clearInterval(this.timeInterval);
        this.setState({ isPlayedOnce: false });
    };


    handlePlayAudio = () => {
        const audio = this.audioRef.current;
        const { currentTime } = this.state;
        let startTime = currentTime;

        if (!this.state.isPlayedOnce) {
            // Jika berhenti lebih dari 5 detik, saat memulai kembali waktunya dikurangi sebanyak 2 detik
            // console.log("currentTime : ", startTime > 5)
            if (startTime > 5) {
                // Mulai dari waktu terakhir yang disimpan dikurangi 2 detik
                startTime -= 2; // Pastikan tidak kurang dari 0
            }
            audio.currentTime = startTime;
            audio.play();
            this.setState({ isPlayedOnce: true });
        }
    };

    handleAudioEnded = () => {
        this.setState({ isPlayedOnce: false, isAudioValid: false });
        this.handleAudioUpdate();
    };

    handleTimeUpdate = () => {
        const { currentTime } = this.audioRef.current;

        // Memperbarui currentTime state dan memanggil handleAudioUpdate untuk memeriksa kondisi
        this.setState({ currentTime }, this.handleAudioUpdate);
    };

    handleShowAudioPlayer = async () => {
        const buttonRect = this.buttonRef.current.getBoundingClientRect();

        const data = await this.props.audioData();

        if (data && Array.isArray(data) && data.length > 0) {
            const filteredAudio = this.filterAudio(data);

            this.setState(
                {
                    audioList: filteredAudio,
                    showAudioPlayer: !this.state.showAudioPlayer,
                    audioPosition: { x: buttonRect.left, y: buttonRect.bottom + 10 },
                },
                () => {
                    if (filteredAudio.length > 0) {
                        this.setState({ selectedAudio: filteredAudio[0] }, () => {
                            this.savedTime = parseFloat(
                                localStorage.getItem(`${this.examProfile.title}_${this.id}_${this.state.selectedAudio?.name}`)
                            );
                            this.checkLastDuration(); // Memeriksa durasi terakhir setelah audio dipilih
                        });
                    }
                }
            );
        } else {
            console.error("Failed to fetch audio data or no valid audio available.");
        }
    };

    handleSelectAudio = (event) => {
        const selectedAudio = JSON.parse(event.target.value);
        this.setState({ selectedAudio }, () => {
            if (this.audioRef.current) {
                this.savedTime = parseFloat(
                    localStorage.getItem(`${this.examProfile.title}_${this.id}_${selectedAudio.name}`)
                );
                this.audioRef.current.load(); // Memuat ulang audio
                this.checkLastDuration(); // Pastikan audio dimulai dari waktu yang disimpan
            }
        });
    };

    handleCloseAudioPlayer = () => {
        if (!this.state.isPlayedOnce) {
            this.setState({
                isPlayedOnce: false,
                currentTime: 0,
                duration: 0,
                showAudioPlayer: false,
                audioPosition: { x: 0, y: 0 },
                audioList: [],
                selectedAudio: null,
                isAudioValid: true,
            });
        }
    };

    handleAudioUpdate = () => {
        const { selectedAudio, audioList } = this.state;

        if (selectedAudio && selectedAudio.end_ts) {
            const endTime = new Date(selectedAudio.end_ts).getTime();
            const currentTimeInMs = Date.now(); // Mengkonversi currentTime ke milidetik

            if (currentTimeInMs >= endTime) {
                // Hentikan audio jika waktu sudah melewati end_ts
                if (this.audioRef.current) {
                    this.audioRef.current.pause();
                    this.setState({
                        isPlayedOnce: false,
                        currentTime: 0,
                        duration: 0,
                        showAudioPlayer: false,
                        audioPosition: { x: 0, y: 0 },
                        audioList: [],
                        selectedAudio: null,
                        isAudioValid: true,
                    })
                }

                // Filter ulang daftar audio dan pilih yang valid
                const filteredAudio = this.filterAudio(audioList);

                this.setState({
                    audioList: filteredAudio,
                    selectedAudio: filteredAudio.length > 0 ? filteredAudio[0] : null, // Pilih audio pertama jika ada
                    isPlayedOnce: false, // Reset state audio yang sedang diputar
                });

                // Tampilkan alert bahwa batas waktu audio telah habis
                AttentionAlert('Batas waktu audio telah habis. Pilih audio lain untuk melanjutkan.');
            }
        }
    };


    formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60).toString().padStart(2, '0');
        return `${minutes}:${seconds}`;
    };

    filterAudio = (audioList) => {
        const now = new Date();
        return audioList.filter(audio => {
            const startTime = new Date(audio.start_ts);
            const endTime = audio.end_ts ? new Date(audio.end_ts) : null;
            if (endTime) {
                return now >= startTime && now <= endTime;
            } else {
                return now > startTime;
            }
        });
    };


    renderAudioPlayer = () => {
        const { isPlayedOnce, currentTime, duration, audioPosition, selectedAudio, audioList, isAudioValid } = this.state;
        const progress = (currentTime / duration) * 100 || 0;

        return (
            <Rnd
                default={{
                    x: audioPosition.x,
                    y: audioPosition.y,
                    width: 320,
                }}
                minWidth={200}
                minHeight={100}
                bounds="window"
                enableResizing={false}
                style={{ zIndex: "4000", position: "absolute" }}
            >
                <div className={classes.container}>
                    <div className={classes.audioPlayer}>
                        <button
                            className={classes.closeButton}
                            onClick={this.handleCloseAudioPlayer}
                            disabled={isPlayedOnce}
                        >
                            <img className={classes.closeImage} src={closeIcon} alt="Close" />
                        </button>

                        <select
                            className={classes.audioSelect}
                            value={JSON.stringify(selectedAudio)}
                            onChange={this.handleSelectAudio}
                            disabled={isPlayedOnce}
                        >
                            {
                                audioList && audioList.length > 0 ? (
                                    audioList.map((value) => (
                                        <option key={value.id} value={JSON.stringify(value)}>
                                            {value.name}
                                        </option>
                                    ))
                                ) : (
                                    <option value="" disabled>No Audio Available</option>
                                )
                            }
                        </select>

                        <div className={classes.buttonWrapper} >
                            <button
                                className={classes.playButton}
                                onClick={this.handlePlayAudio}
                                disabled={isPlayedOnce || !isAudioValid}  // Disable button if audio is invalid
                            >
                                {isPlayedOnce ? "Playing..." : "Play"}
                            </button>

                            <div className={classes.infoIcon}>
                                <img src={info} alt={"info"} />
                                {/* <span className={classes.tooltip}>
                                </span> */}
                                <div className={classes.tooltip}>
                                    {this.state.selectedAudio?.description}
                                </div>
                            </div>
                        </div>

                        <div className={classes.timeBarContainer}>
                            <div className={classes.timeBar} style={{ width: `${progress}%` }}></div>
                        </div>

                        <div className={classes.timeInfo}>
                            <span>{this.formatTime(currentTime)}</span>
                            <span>{this.formatTime(duration)}</span>
                        </div>

                        <audio
                            ref={this.audioRef}
                            src={selectedAudio?.url}
                            onPlay={this.startSavingCurrentTime}
                            onEnded={this.stopSavingCurrentTime}
                            onTimeUpdate={this.handleTimeUpdate} // Memperbarui waktu dan memeriksa kondisi setiap detik

                        />
                    </div>
                </div>
            </Rnd>
        );
    }

    render() {
        const { showAudioPlayer, isPlayedOnce } = this.state;

        return (
            <>
                <button
                    ref={this.buttonRef}
                    className={`${classes.customButton} ${classes.audioPlayer}`}
                    onClick={this.handleShowAudioPlayer}
                    disabled={isPlayedOnce || showAudioPlayer}
                >
                    Audio Player
                </button>
                {showAudioPlayer && this.renderAudioPlayer()}
            </>
        );
    }
}

AudioPlayer.propTypes = {
    id: PropTypes.string.isRequired,
    examProfile: PropTypes.object.isRequired,
    initExam: PropTypes.bool.isRequired,
    audioData: PropTypes.func,
}

export default AudioPlayer;
