import React from "react";
import ProfileShow from "../../components/ProfileShow";
import { isInsideSafeExamBrowser } from "../../libs/proctor_utils";
import {
  MAX_DEVICE_ERROR_RETRY,
  checkBlackDisplay,
  initializeMedias,
  showMediaError,
} from "../../libs/utils";
import { Lang } from "../../libs/Language";
import Tips from "../../components/Tips";
import { AttentionAlert, ErrorAlert } from "../../components/Alert";
import CoolButton from "../../components/CoolButton";

export const __Preparation__ = function (_this, classes) {
  _this.initWebCamRef = React.createRef();
  _this.initScreenCaptureRef = React.createRef();
  _this.browser = null;

  this.recoverVideoForPreparation = () => {
    _this.firstTimeValidation = false;
    setTimeout(() => {
      if (_this.initWebCamRef.current !== null) {
        _this.initWebCamRef.current.srcObject = _this.state.userMediaStream;
      }
      if (
        _this.initScreenCaptureRef.current !== null &&
        _this.mode !== "mobile"
      ) {
        _this.initScreenCaptureRef.current.srcObject =
          _this.state.displayMediaStream;
      }
    }, 1000);
  };
  this.firstTimeInitialization = () => {
    return (
      _this.state.errorCaptureStatus.face === 0 &&
      _this.state.errorCaptureStatus.screen === 0 &&
      _this.state.errorCaptureStatus.webrtc === 0
    );
  };
  this.getDeviceInitializationError = () => {
    let errorDevice = "";
    if (
      _this.state.errorCaptureStatus.face > 0 &&
      _this.state.errorCaptureStatus.screen > 0
    ) {
      errorDevice = "webcam & screencapture";
    } else if (_this.state.errorCaptureStatus.face > 0) {
      errorDevice = "webcam";
    } else {
      errorDevice = "screencapture";
    }
    return errorDevice;
  };
  this.handleActiveShareVideo = async () => {
    if (_this.state.numberOfScreen > 1) {
      AttentionAlert(
        "Jumlah layar terhubung ke device anda ada lebih dari satu. Anda hanya diperbolehkan untuk menggunakan layar utama saja. Putuskan layar tambahan anda."
      );
      return;
    }
    if (_this.state.numberOfScreen !== -1) {
      _this.setState({ mediaError: {} });
      initializeMedias(
        _this.state.examProfile.video_resolution,
        true,
        _this.mode !== "mobile",
        true,
        {
          face:
            this.firstTimeInitialization() ||
            _this.state.errorCaptureStatus.face > 0,
          screen:
            this.firstTimeInitialization() ||
            _this.state.errorCaptureStatus.screen > 0,
          webrtc:
            this.firstTimeInitialization() ||
            _this.state.errorCaptureStatus.webrtc > 0,
        },
        (stream) => {
          if (stream === false) {
            if (_this.state.participantProfile.validation_status === 3) {
              _this.firstTimeValidation = false;
              _this.setState({
                userMediaStream: false,
                initExam: false,
                validationInProgress: false,
              });
            }
            return;
          }

          ////////// THIS IS FOR SUCCESS
          _this.initWebCamRef.current.srcObject = stream;

          // If the image is valid, update state and proceed
          _this.setState({ userMediaStream: stream });

          // Handle audio sensitivity
          if (_this.state.examProfile.audio_sensitivity !== "disabled") {
            _this._audio.analyzeStream(stream);
          }

          // Reset error capture status
          _this.setState((prevState) => ({
            errorCaptureStatus: {
              ...prevState.errorCaptureStatus,
              face: 0,
            },
          }));
          return;
          //////////////////


          // _this.setState((prevState) => ({
          //   errorCaptureStatus: {
          //     ...prevState.errorCaptureStatus,
          //     face: 0,
          //   },
          // }));

          // _this.initWebCamRef.current.srcObject = stream;
          // _this.setState({ userMediaStream: stream });

          // if (_this.state.examProfile.audio_sensitivity !== "disabled") {
          //   // handle audio analysis
          //   _this._audio.analyzeStream(stream);
          // }

          // Set video source to the incoming stream
          _this.initWebCamRef.current.srcObject = stream;

          // Ensure the video metadata is loaded before proceeding
          _this.initWebCamRef.current.onloadedmetadata = async () => {
            _this.initWebCamRef.current.play();

            // Check for black display immediately
            await checkBlackDisplay(_this.initWebCamRef.current, (isBlack) => {
              if (isBlack) {
                // If the image is black, reset the stream and show alert
                ErrorAlert("Jangan Menutupi Kamera / Disable Kamera dari Perangkat!");
                _this.initWebCamRef.current.srcObject = null;
                _this.setState({ userMediaStream: null });
              } else {
                // If the image is valid, update state and proceed
                _this.setState({ userMediaStream: stream });

                // Handle audio sensitivity
                if (_this.state.examProfile.audio_sensitivity !== "disabled") {
                  _this._audio.analyzeStream(stream);
                }

                // Reset error capture status
                _this.setState((prevState) => ({
                  errorCaptureStatus: {
                    ...prevState.errorCaptureStatus,
                    face: 0,
                  },
                }));
              }
            });
          };



          // Check for black display before setting stream
          // setTimeout(() => {
          //   checkBlackDisplay(stream, (isBlack) => {
          //     if (isBlack) {
          //       // If the image is black, reset the stream and show alert
          //       ErrorAlert(
          //         "Jangan Menutupi Kamera / Disable Kamera dari Perangkat!"
          //       );
          //       _this.initWebCamRef.current.srcObject = null;
          //       _this.setState({ userMediaStream: null });
          //     } else {
          //       // If the image is valid, proceed to set stream and state
          //       _this.initWebCamRef.current.srcObject = stream;
          //       _this.setState({ userMediaStream: stream });

          //       // Handle audio sensitivity
          //       if (_this.state.examProfile.audio_sensitivity !== "disabled") {
          //         _this._audio.analyzeStream(stream);
          //       }

          //       // Reset error capture status
          //       _this.setState((prevState) => ({
          //         errorCaptureStatus: {
          //           ...prevState.errorCaptureStatus,
          //           face: 0,
          //         },
          //       }));
          //     }
          //   });
          // }, 1000);

          // setTimeout(() => {
          //   checkBlackDisplay(_this.initWebCamRef.current, (isBlack) => {
          //     console.log("Is Black Image: ", isBlack);
          //     if (isBlack) {
          //       _this.initWebCamRef.current.srcObject = null;
          //       _this.setState({ userMediaStream: null });
          //       ErrorAlert(
          //         "Jangan Menutupi Kamera / Disable Kamera dari Perangkat!"
          //       );
          //     }
          //   });
          // }, 1000);

        },
        (stream) => {
          // Hentikan screen sharing sebelumnya jika ada
          if (_this.state.displayMediaStream) {
            _this.state.displayMediaStream.getTracks().forEach((track) => track.stop());
            _this.initScreenCaptureRef.current.srcObject = null;
            _this.setState({
              displayMediaStream: null,
            });
          }

          this.streamByBrowser(stream);

          _this.setState((prevState) => ({
            errorCaptureStatus: {
              ...prevState.errorCaptureStatus,
              screen: 0,
            },
          }));
        },
        (stream) => {
          if (stream === false) {
            if (_this.state.participantProfile.validation_status === 3) {
              _this.firstTimeValidation = false;
              _this.setState({
                webRtcMediaStream: false,
                initExam: false,
                validationInProgress: false,
              });
            }
            return;
          }
          _this.setState({ webRtcMediaStream: stream });
          _this.setState((prevState) => ({
            errorCaptureStatus: {
              ...prevState.errorCaptureStatus,
              webrtc: 0,
            },
          }));
        },
        (typeError /*face/screen/webrtc*/, error) => {
          _this.setState((prevState) => ({
            errorCaptureStatus: {
              ...prevState.errorCaptureStatus,
              [typeError]: prevState.errorCaptureStatus[typeError] + 1,
            },
          }));
          _this.setState((prevState) => ({
            mediaError: {
              ...prevState.mediaError,
              [typeError]: error,
            },
          }));
        }
      );
    } else if (this.browser !== "Safari" && !isInsideSafeExamBrowser()) {
      window
        .getScreenDetails()
        .then((v) => { })
        .catch((e) => {
          AttentionAlert(
            "Anda belum mengaktifkan Window Management Permission. Aktifkan Window Management Permission."
          );
        });
    }
  };

  this.streamByBrowsers = (stream) => {
    let display = "";
    if (_this.browser === "Chrome") {
      display = stream.getVideoTracks()[0].getSettings().displaySurface;
    } else if (_this.browser === "Firefox") {
      display = stream.getVideoTracks()[0].label;
    } else {
      // Safari or other
      display = stream.getVideoTracks()[0].label;
    }

    let validDisplay =
      display === "monitor" ||
      display === "Primary Monitor" ||
      display === "Screen ";
    if (window.electron) {
      validDisplay = true;
    }
    let entireScreen = validDisplay ? stream : null;

    // if (display !== 'monitor' && display !== 'Primary Monitor' && display !== "Screen ") {
    if (!validDisplay) {
      stream.getTracks().forEach((track) => track.stop());
      AttentionAlert(`Mohon Untuk Memilih Entire Screen / Seluruh Layar.`);
      _this.setState({ displayMediaStream: null });
      return;
    }

    stream.getVideoTracks()[0].addEventListener("ended", async () => {
      const confirmed = await ErrorAlert(
        "Terjadi kesalahan pada kamera dan screen capture anda, sistem akan keluar. Anda dapat login kembali."
      );

      if (confirmed) {
        _this._action.onLogout();
      }
    });

    // if (display === 'monitor' || display === 'Primary Monitor' || display === "Screen ") {
    if (validDisplay) {
      _this.initScreenCaptureRef.current.srcObject = entireScreen;
      _this.setState({ displayMediaStream: stream });
    }

    _this._action.sendWebCamImageToServer();
  };

  this.streamByBrowser = (stream) => {
    let display = "";

    // Deteksi browser dan tipe layar
    if (_this.browser === "Chrome") {
      display = stream.getVideoTracks()[0].getSettings().displaySurface;
    } else if (_this.browser === "Firefox") {
      display = stream.getVideoTracks()[0].label;
    } else {
      display = stream.getVideoTracks()[0].label; // Safari atau lainnya
    }

    // Validasi jenis layar
    let validDisplay =
      display === "monitor" ||
      display === "Primary Monitor" ||
      display === "Screen ";

    if (window.electron) {
      validDisplay = true; // Override untuk aplikasi desktop
    }

    let entireScreen = validDisplay ? stream : null;

    // Jika validasi gagal, hentikan stream dan tampilkan peringatan
    if (!validDisplay) {
      stream.getTracks().forEach((track) => track.stop());
      AttentionAlert(`Mohon Untuk Memilih Entire Screen / Seluruh Layar.`);
      _this.setState({ displayMediaStream: null }); // Reset state
      return;
    }

    // Tangani event jika stream dihentikan oleh pengguna
    stream.getVideoTracks()[0].addEventListener("ended", async () => {
      const confirmed = await ErrorAlert(
        "Terjadi kesalahan pada kamera dan screen capture Anda. Sistem akan keluar. Anda dapat login kembali."
      );

      if (confirmed) {
        _this._action.onLogout(); // Logout jika konfirmasi diterima
      }
    });

    // Jika validasi berhasil, setel stream ke elemen video dan state
    if (validDisplay) {
      _this.initScreenCaptureRef.current.srcObject = entireScreen; // Setel stream ke elemen video
      _this.setState({ displayMediaStream: stream }); // Perbarui state
    }

    // Kirim data webcam ke server
    _this._action.sendWebCamImageToServer();
  };


  this.renderFaceValidationCondition = () => {
    // Debug("VALIDATION ");
    // return _this.state.userMediaStream !== null && _this._validation.onValidation();
    // return setTimeout(() => {
    //     _this.state.userMediaStream !== null && _this._validation.onValidation();
    // }, 1000)

    return this.conditionOnFaceValidation() ? (
      <div>
        {_this.state.participantProfile?.validation_status === 0 ? (
          <div
            className={classes.btnOk}
            onClick={() => {
              _this._validation.onValidation();
            }}
            style={{ marginTop: "5px" }}
          >
            {_this.state.examProfile.valid_by_default
              ? Lang("Tangkapan Wajah Untuk Lanjut", _this.state.lang, {
                en: `Face Detection to Proceed`,
              })
              : Lang("Validasi Wajah", _this.state.lang, {
                en: `Face Validation`,
              })}
          </div>
        ) : null}
      </div>
    ) : null;
  };
  this.onRenderPrepareExam = () => {
    const handleOnValidationStatus = () => {
      if (_this.state.participantProfile.validation_status === 2) {
        return (
          <div
            className={""}
            style={{
              marginTop: "5px",
              padding: "10px 15px",
              border: "1px solid red",
              color: "red",
              fontWeight: "600",
            }}
          >
            <span>Blocked</span>
          </div>
        );
      } else if (
        _this.mode !== "mobile" &&
        (_this.state.userMediaStream === null ||
          _this.state.displayMediaStream === null ||
          _this.state.numberOfScreen === -1)
      ) {
        if (
          // ALLOW_NO_DEVICE
          _this.state.participantProfile.validation_status === 3
        ) {
          const ec = _this.state.errorCaptureStatus;
          if (
            ec.face >= MAX_DEVICE_ERROR_RETRY - 1 ||
            ec.screen >= MAX_DEVICE_ERROR_RETRY - 1 ||
            ec.webrtc >= MAX_DEVICE_ERROR_RETRY - 1
          ) {
            return (
              <div
                className={classes.btnOk}
                style={{
                  marginTop: "5px",
                }}
                onClick={() => {
                  // this.handleActiveShareVideo()
                  _this.setState((prevState) => ({
                    errorCaptureStatus: {
                      ...prevState.errorCaptureStatus,
                      face:
                        prevState.errorCaptureStatus["face"] === 0
                          ? 0
                          : prevState.errorCaptureStatus["face"] + 1,
                      screen:
                        prevState.errorCaptureStatus["screen"] === 0
                          ? 0
                          : prevState.errorCaptureStatus["screen"] + 1,
                      webrtc:
                        prevState.errorCaptureStatus["webrtc"] === 0
                          ? 0
                          : prevState.errorCaptureStatus["webrtc"] + 1,
                    },
                    initExam: false,
                  }));

                  /* TIDAK PERLU MENGIRIM LAGI, KARENA SUDAH DI SETTING OLEH MANAGEMENT */

                  // Bugnya karena saat di klik status validasi berubah menjadi invalid lagi
                  // if (_this.commonWs) {
                  //     if (_this.commonWs.sendUpdateValidationStatus(
                  //         "proctornodevice",
                  //         _this.context.profile.id,
                  //         "valid_no_device", {
                  //         camera: _this.state.errorCaptureStatus["face"] === 0 ? "on" : "off",
                  //         screen: _this.state.errorCaptureStatus["screen"] === 0 ? "on" : "off"
                  //     }) === false) {
                  //         alert("Gagal meng-update validasi user, ulangi kembali.");
                  //     }
                  // }
                  // else {
                  //     alert("Gagal meng-update validasi user, ulangi kembali.");
                  // }
                }}
              >
                {Lang("Lanjut Menuju Ruang Ujian", _this.state.lang, {
                  en: `Continue to the Exam Room`,
                })}
              </div>
            );
          }
        }
        return (
          <>
            <CoolButton
              className={classes.btnOk}
              marginTop={"5px"}
              gradient={"linear-gradient(45deg, #0561ff, #464fa6)"}
              fontSize={".85rem"}
              fontColor={"white"}
              style={{
                marginTop: "5px",
              }}
              onClick={() => {
                this.handleActiveShareVideo();
                if (_this.state.examProfile.url_in_iframe === false) {
                  _this._pictureinpicture.initPipWindow();
                }
              }}
            >
              {_this.state.numberOfScreen !== -1
                ? Lang(
                  "Aktifkan Web Camera dan Screen Capture",
                  _this.state.lang,
                  { en: `Activate Web Camera and Screen Capture` }
                )
                : Lang(
                  `Aktifkan Window Management Permission`,
                  _this.state.lang,
                  { en: `Activate Window Management Permission` }
                )}
            </CoolButton>
            {
              // ALLOW_NO_DEVICE
              _this.state.participantProfile.validation_status === 3 &&
                this.firstTimeInitialization() === false &&
                this.getDeviceInitializationError() !== "" ? (
                <>
                  <div
                    className={classes.btnHelp}
                    style={{
                      marginTop: "10px",
                      fontSize: ".75rem",
                      maxWidth: "400px",
                      fontStyle: "italic",
                      fontWeight: "normal",
                      textAlign: "center",
                    }}
                  >
                    * Kami mendeteksi anda tidak bisa mengaktifkan{" "}
                    {this.getDeviceInitializationError()} anda, anda dapat
                    mencoba memperbaikinya dengan cara membaca petunjuk dari
                    tombol troubleshooting di kiri atas halaman ini
                  </div>
                  <div
                    className={classes.btnHelp}
                    style={{
                      marginTop: "10px",
                      fontSize: ".75rem",
                      maxWidth: "400px",
                      fontStyle: "italic",
                      fontWeight: "normal",
                      textAlign: "center",
                    }}
                  >
                    Setelah melakukan troubleshooting, klik Aktifkan Web Camera
                    dan Screen Capture kembali
                  </div>
                </>
              ) : (
                <></>
              )
            }
          </>
        );
      }
      return null;
    };

    return (
      <div
        className={[
          classes.container_prepare,
          classes.ascroll,
          classes.FadeInPage1s,
        ].join(" ")}
      >
        <Tips />
        {_this._component.renderFaceReference()}
        <div className={[classes.prepare, classes.ascroll].join(" ")}>
          {_this._action.onCountDown()}
          {_this.mode === "mobile"
            ? _this._component.renderHelpMobile()
            : _this._component.renderHelp()}

          <div
            style={{
              width: _this.mode !== "mobile" ? "" : "70%",
            }}
          >
            {_this._chatws.renderProctorBroadcastChat(
              _this.state.broadcastFromProctorerMessage
            )}
          </div>

          <div className={classes.prepare_profile}>
            <div>
              <ProfileShow
                profile={_this.state.participantProfile}
                numberOfScreen={_this.state.numberOfScreen}
              />
            </div>
            {_this.context.profile.photo && (
              <div className={classes.prepare_profile_img}>
                <img
                  alt="face-id"
                  src={_this.context.profile.photo}
                  style={{
                    maxHeight: "200px",
                  }}
                />
              </div>
            )}
          </div>
          <div className={classes.prepare_top}>
            <div className={classes.prepare_top_info}>
              <div className={classes.logo}>i</div>
              {_this.mode !== "mobile" ? (
                <span className={classes.info}>
                  {Lang(
                    `Dengan mengaktifkan Web Camera dan Memilih 'Entire Screen/Seluruh Layar' Pada Screen Capture, anda telah menyetujui syarat dan ketentuan yang ditetapkan oleh penyelenggara ujian.`,
                    _this.state.lang,
                    {
                      en: `By activating the Web Camera and selecting 'Entire Screen/Entire Screen' in Screen Capture, you have agreed to the terms and conditions set by the exam organizer.`,
                    }
                  )}
                </span>
              ) : (
                <span className={classes.info}>
                  {Lang(
                    `Dengan mengaktifkan Web Camera, anda telah menyetujui syarat dan ketentuan yang ditetapkan oleh penyelenggara ujian.`,
                    _this.state.lang,
                    `By activating the Web Camera, you have agreed to the terms and conditions set by the exam organizer.`
                  )}
                </span>
              )}
            </div>
            {/* {_this.state.mediaError && (
              <div className={classes.device_warning}>
                <div className={classes.info}>
                  !!! Error Activating Your Device !!!
                </div>
                {me}
                <div className={classes.info}>
                  Mohon untuk membaca troubleshooting di pojok kiri atas laman
                  anda.
                </div>
              </div>
            )} */}
            {showMediaError(_this.state.mediaError)}
            {handleOnValidationStatus()}
            {_this.mode === "mobile" && _this.state.userMediaStream === null ? (
              <div
                className={classes.btnOk}
                style={{
                  marginTop: "5px",
                }}
                onClick={() => {
                  this.handleActiveShareVideo();
                }}
              >
                {Lang(`Aktifkan Web Camera`, _this.state.lang, {
                  en: `Activate Web Camera`,
                })}
              </div>
            ) : null}

            {this.renderFaceValidationCondition()}
          </div>
          {_this._component.renderMediaParticipants()}
          {_this._component.helpAlert()}
          {_this._chatws.privateChat(_this, classes)}
        </div>
        {_this._manual.renderManual()}
      </div>
    );
  };

  this.conditionOnFaceValidation = () => {
    return (
      _this.state.userMediaStream !== null &&
      ((_this.state.displayMediaStream !== null && _this.mode !== "mobile") ||
        _this.mode === "mobile")
    );
  };
};
