import React, { createRef } from 'react';
import classes from "./__index__.module.scss";
import { renderHeader } from './Header';
import user from "../../../assets/imgs/user.png";
import online from "../../../assets/imgs/online.png";
import PropTypes from 'prop-types';
import { urlFaceInWebCam } from '../../../libs/proctor_utils';
import { fetchDetailParticipant, updateImage } from './Action';
import { renderSearch, renderWindow } from './Component';
import notes2 from "../../../assets/imgs/notes2.png";
import screenshot2 from "../../../assets/imgs/screenshot2.png";
import collage from "../../../assets/imgs/collage.png";
import chat2 from "../../../assets/imgs/chat2.png";
import { renderMenu } from './LeftMenu';
import { renderParticipant } from './Participant';
import validusers from "../../../assets/imgs/valid-users.png";
import invalidusers from "../../../assets/imgs/invalid-users.png";
import blocked from "../../../assets/imgs/blocked.png";

class ParticipantDesk extends React.Component {
    constructor(props) {
        super(props);

        this.searchRef = React.createRef();
        this.menus = {
            participants: {
                title: 'All Participants',
                img: user
            },
            online: {
                title: 'Online Participants',
                img: online,
            },
            valid: {
                title: 'Valid Participants',
                img: validusers
            },
            invalid: {
                title: 'Invalid Participants',
                img: invalidusers
            },
            blocked: {
                title: 'Blocked Participants',
                img: blocked
            }

            // highlight: {
            //     title: 'Highlight',
            //     img: star
            // },
            // flag: {
            //     title: 'Flag',
            //     img: flag
            // }
        };

        this.participants = {};
        this.updateParticipants();

        this.MOBILE_PAIRING = false
        this.state = {
            participants: this.participants,
            chosen: {
                uuid: this.props.participantUUId,
                profile: null,
            },
            isAudioMuted: false,
            isloading: true,

            chosenWindow: 'chat', // collage, notes, screenshot, chat
            chosenMenu: 'participants',

            faceImgUrl: null,
            screenImgUrl: null,
            mobilePairingImageUrl: null,

            screenshotNotes: [],

            selectedImage: "",
            isImageModalOpen: false,

            mode: "screenshot", // screenshot, view-screenshot
        };

        this.windowOptions = {
            'chat': {
                img: chat2
            },
            'collage': {
                img: collage,
            },
            'notes': {
                img: notes2
            },
            'screenshot': {
                img: screenshot2
            }
        }

        this.notesRef = createRef();

        setInterval(() => {
            this.updateParticipants();
            this.setState({ participants: this.participants });
        }, 10000);
    }

    updateParticipants() {
        for (let p in this.props.participants) {
            const par = this.props.participants[p];
            this.participants[p] =
            {
                id: p,
                uuid: par.uuid,
                name: par.username,
                img: urlFaceInWebCam(p, par.folder_rand, Math.round(Date.now() / 30000)),
                status: par.status,
                validation_status: par.validation_status,
                folder_rand: par.folder_rand
            }
            //)
        }
        // this.setState({ participants: this.participants });
    }

    componentDidMount() {
        fetchDetailParticipant(this, this.state.chosen.uuid, (participantId) => {
            this.props.onChatPageEnter(participantId);
        });

        setTimeout(() => {
            updateImage(this);
        }, 1000);

        //make flicker
        clearInterval(this.getImageInterval);
        this.getImageInterval = setInterval(() => {
            if (!this.state.chosen.profile) {
                return;
            }
            // make flicker inside
            fetchDetailParticipant(this, this.state.chosen.uuid)

            // doesn't make flicker
            updateImage(this);

            // send store participant 
            this.props.onSendStoreParticipantImage(this.state.chosen.profile.id)
        }, 5000);

        setTimeout(() => {
            const el = document.getElementById(this.state.chosen.uuid)
            el?.scrollIntoView(
                { behavior: "smooth" }
            );
        }, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.getImageInterval);
    }

    componentDidUpdate() {
    }

    render() {
        return <div className={[classes.container, classes.FadeInPage1s].join(" ")}>
            <div className={classes.container_body}>
                <div className={classes.container_body_menu}>
                    {renderMenu(this)}
                </div>
                <div className={classes.container_body_participant}>
                    <div className={classes.container_body_participant_search}>
                        {renderSearch(this)}
                    </div>
                    <div className={[classes.container_body_participant_par, classes.ascroll].join(" ")}>
                        {renderParticipant(this)}
                    </div>
                </div>
                <div className={classes.container_body_content}>
                    <div className={classes.container_body_content_header}>
                        {renderHeader(this)}
                    </div>
                    <div className={classes.container_body_content_body}>
                        {renderWindow(this)}
                    </div>
                </div>
            </div>
        </div >
    }
}

ParticipantDesk.propTypes = {
    onClose: PropTypes.func,
    participantUUId: PropTypes.string,
    participants: PropTypes.object,
    currentTs: PropTypes.string,
    onCallParticipant: PropTypes.func,
    openImage: PropTypes.bool,
    onImageClose: PropTypes.func,
    onImageOpen: PropTypes.func,
}

export default ParticipantDesk;