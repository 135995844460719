import React, { Component } from 'react';
import QRCode from 'qrcode.react';
import classes from './QRCodeMobilePairing.module.scss';
import amaninLogo from '../assets/imgs/logoBW.png'
import AuthContext from "../store/auth-context";
import mobilepairing from "../assets/imgs/mobilepairing.png";
import { getExamId, getFullURL } from '../libs/utils';
import Swal from 'sweetalert2';

class QRCodeMobilePairing extends Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.state = {
            showQRCode: false,
            showTitle: false,
        };
    }

    // handleChange = (event) => {
    //     this.setState({ data: event.target.value });
    // };

    toggleQRCode = () => {
        this.setState((prevState) => ({ showQRCode: !prevState.showQRCode }));
    };

    handleCopy = () => {
        const { pairing_token } = this.context.profile;
        const qrValue = `${getFullURL()}/id/${getExamId()}/mpid/${pairing_token}`;

        navigator.clipboard.writeText(qrValue).then(() => {
            Swal.fire({
                title: 'Copied!',
                text: 'The QR code value has been copied to the clipboard.',
                icon: 'success',
                timer: 1500,
                showConfirmButton: false,
            });
        }).catch((err) => {
            console.error('Failed to copy text: ', err);
        });
    };

    render() {
        const { pairing_token } = this.context.profile
        // console.log("QRCODE URL : ", `${window.location.host}/id/${getExamId()}/mpid/${pairing_token}`)
        return (
            <div className={classes.container}>
                {this.state.showTitle && <div className={classes.container_title}>
                    Mobile Pairing
                </div>
                }
                <div
                    className={classes.buttonContainer}
                    onMouseEnter={() => this.setState({ showTitle: true })}
                    onMouseLeave={() => this.setState({ showTitle: false })}
                >
                    <button className={`${classes.customButton} ${classes.mobilePairing}`} onClick={this.toggleQRCode}>
                        <img src={mobilepairing} height={"30px"} />
                    </button>
                </div>
                <div className={[classes.qr_code, this.state.showQRCode ? classes.show : classes.hide].join(" ")}>
                    {/* <QRCode value={pairing_token} */}
                    <QRCode value={`${window.location.host}/id/${getExamId()}/mpid/${pairing_token}`}
                        className={classes.qr_code_item}
                        renderAs="canvas"
                        size={250}
                        level="H" // High error correction level
                        imageSettings={{
                            src: amaninLogo,
                            x: null,
                            y: null,
                            height: 40,
                            width: 40,
                            excavate: true,
                        }} />
                    <button
                        className={classes.copyButton}
                        onClick={this.handleCopy}
                    >
                        Copy
                    </button>
                    {/* <input
                        type="text"
                        value={this.state.data}
                        onChange={this.handleChange}
                        placeholder="Masukkan Data"
                        style={{ width: "250px", height: "50px" }}
                    /> */}
                </div>
            </div>
        );
    }
}

export default QRCodeMobilePairing;
