/* global chrome */
/* global browser */
import { Debug, Err } from "./logger";
import { fetchDataBlob, fetchDataJSON, getAccessToken, getExamId } from "./utils";

const API_URL = "/api/proktor";
const API_URL_CONF = "/confapi";
const API_URL_FILE = "/fileapi/api";
const API_URL_COMMON = "/commonws/api";
export const VERSION = "1.2.0.20241023";

// export const TELKOM_CASE = false;
// telkom case
// export const TELKOM_CASE = true;

export const fetchJSONMessage = async (action, body_content, error_handler) => {
    const body = {
        action: action,
        body: body_content,
        authorization: `${getAccessToken()}`,
        examid: `${getExamId()}`
    };
    return fetchDataJSON(API_URL, body, (error) => {
        error_handler(error);
    });
}

export const fetchJSONMessageConf = async (action, body_content, error_handler) => {
    const body = {
        action: action,
        body: body_content,
        examid: `${getExamId()}`
    };
    return fetchDataJSON(API_URL_CONF, body, (error) => {
        error_handler(error);
    });
}

export const fetchJSONMessageBlob = async (action, body_content, blob, error_handler) => {
    const body = {
        action: action,
        body: JSON.stringify(body_content),
        examid: `${getExamId()}`
    };
    return fetchDataBlob(API_URL_FILE, body, blob, (error) => {
        error_handler(error);
    });
}

export const fetchJSONMessageFile = async (action, body_content, error_handler) => {
    const body = {
        action: action,
        body: body_content,
        examid: `${getExamId()}`
    };
    return fetchDataJSON(API_URL_FILE, body, (error) => {
        error_handler(error);
    });
}

export const fetchJSONMessageCommon = async (action, body_content, error_handler) => {
    const body = {
        action: action,
        body: body_content,
        examid: `${getExamId()}`
    };
    return fetchDataJSON(API_URL_COMMON, body, (error) => {
        error_handler(error);
    });
}

export const fetchUpdateJSONMessageConf = async (action, body_content, error_handler) => {
    const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        action: action,
        examid: `${getExamId()}`,
        body: body_content
    };
    return fetchDataJSON(API_URL_CONF, requestOptions, (error) => {
        error_handler(error);
    });
}

export const fetchDeleteJSONMessageConf = async (action, body_content, error_handler) => {
    const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        action: action,
        body: body_content
    };
    return fetchDataJSON(API_URL_CONF, requestOptions, (error) => {
        error_handler(error);
    });
}

export const fetchNotes = async (proctorerId, participantId, notes, flagAttention, error_handler) => {
    return fetchJSONMessage("store-notes", {
        proctorerId: String(proctorerId),
        participantId: String(participantId),
        notes: String(notes),
        flagAttention: flagAttention === true ? "1" : "0"
    }, error_handler)
}

export const fetchLoginGeneral = async (username, password, pperp, error_handler) => {
    return fetchJSONMessageConf("general-login", {
        username: String(username),
        password: String(password),
        pperp: pperp,
    }, error_handler)
}

export const fetchDirectUserLogin = async (body, error_handler) => {
    return fetchJSONMessageConf("direct-user-login", {
        username: body.username,
        token: body.token
    }, error_handler)
}

export const fetchMobilePairingLogin = async (body, error_handler) => {
    return fetchJSONMessageConf("mobile-pairing-login", {
        token: body.token
    }, error_handler)
}

export const fetchDirectUserToken = async (token, error_handler) => {
    return fetchJSONMessageConf("get-direct-user-token", { token }, error_handler)
}
export const fetchDirectProctorer = async (token, error_handler) => {
    return fetchJSONMessageConf("get-direct-proctorern", { token }, error_handler)
}

export const fetchExamProfileInitial = async (error_handler) => {
    return fetchJSONMessage("get-exam-profile-initial", {}, error_handler);
}

export const fetchExamProfile = async (error_handler) => {
    return fetchJSONMessage("get-exam-profile", {}, error_handler);
}

export const fetchCurrentTs = async (error_handler) => {
    return fetchJSONMessage("get-current-ts", {}, error_handler);
}

export const fetchImageLength = async (id, ts, error_handler) => {
    return fetchJSONMessage("get-image-length", {
        id: String(id),
        ts: String(ts)
    }, error_handler);
}

export const fetchProctorerProfiles = async (error_handler) => {
    return fetchJSONMessage("get-proctorer-profiles", {}, error_handler);
}

export const fetchGetEventsSetting = async (error_handler) => {
    return fetchJSONMessageConf("get-events-setting", {}, error_handler);
}

export const fetchProctorerProfileById = async (proctorerId, error_handler) => {
    return fetchJSONMessage("get-proctorer-profile", {
        id: proctorerId
    }, error_handler);
}

export const fetchParticipantsByParams = async (useSearch, searchOrproctorerId, error_handler) => {
    // KEEP USING CPP, THE LOGIC IS IN CPP
    return fetchJSONMessage("get-participants", {
        id: searchOrproctorerId,
        search: useSearch
    }, error_handler);
}

export const fetchCredRequestToExam = async (username, password, coursename, error_handler) => {
    // KEEP USING CPP, THE LOGIC IS IN CPP
    return fetchJSONMessageConf("exam-cred-request", {
        username: username,
        password: password,
        coursename: coursename,
    }, error_handler);
}

export const fetchParticipantsByProctorId = async (proctorerId, error_handler) => {
    // KEEP USING CPP, THE LOGIC IS IN CPP
    return fetchJSONMessage("get-participants", {
        id: proctorerId
    }, error_handler);
}

export const fetchParticipantsBySearch = async (searchText, error_handler) => {
    // KEEP USING CPP, THE LOGIC IS IN CPP
    return fetchJSONMessage("get-participants", {
        searchText: searchText,
        search: true
    }, error_handler);
}


export const fetchParticipantByParticipantId = async (participantUUId, error_handler) => {
    // KEEP USING CPP
    return fetchJSONMessage("get-participant", {
        uuid: participantUUId
    }, error_handler);
}

// export const fetchParticipantsAll = async (parcticipantId, error_handler) => {
//     return fetchJSONMessageConf("get-participant", {
//     }, error_handler);
// }

//Screenshot
export const fetchScreenshotList = async (participantId, folderRand, error_handler) => {
    return fetchJSONMessageFile("get-screenshot-list", {
        id: participantId,
        'folder-rand': folderRand
    }, error_handler);
    // [136xxxx, 165xxx]
}
export const fetchSaveScreenShotList = async (body, error_handler) => {
    return fetchJSONMessageFile("save-screenshot-image", {
        profile: {
            id: body.id,
            img_face: body.img_face,
            img_screen: body.img_screen,
            img_mobile: body.img_mobile,
            notes: body.notes,
            ts: body.ts,
            "folder-rand": body.folder_rand,
        },
    }, error_handler);
}

export const fetchUpdateScreenshotNotes = async (body, error_handler) => {
    Debug("Data : ", body)
    return fetchJSONMessageFile("update-screenshot-notes", { ...body }, error_handler);
}
export const fetcDeleteScreenshotNotes = async (body, error_handler) => {
    Debug("Data Delete : ", body)
    return fetchJSONMessageFile("delete-screenshot-notes", { ...body }, error_handler);
}

export const urlScreenShotList = (participantId, folderRand, imageName) => {
    return `${API_URL_FILE}/image.jpg?type=screenshot&participantId=${participantId}&imageName=${imageName}&folderRand=${folderRand}&examid=${getExamId()}`;
}

export const urlKTPImage = (parcticipantId, folderRand) => {
    return `${API_URL_FILE}/imageapi?type=ktp&participantid=${parcticipantId}&folderrand=${folderRand}&examid=${getExamId()}`;
}

export const urlFaceInKTPImage = (parcticipantId, folderRand) => {
    return `${API_URL_FILE}/imageapi?examid=${getExamId()}&type=faceinktp&participantid=${parcticipantId}&folderrand=${folderRand}`;
}

export const urlPhotoID = (participantId, folderRand) => {
    return `${API_URL_FILE}/imageapi?examid=${getExamId()}&type=photoid&participantid=${participantId}&folderrand=${folderRand}`;
}

export const urlProctorerRandom = () => {
    return `${API_URL_FILE}/imageapi?examid=${getExamId()}&type=proctorer`;
}

export const urlProctorer = (proctorerId) => {
    return `${API_URL_FILE}/imageapi?examid=${getExamId()}&type=proctorer&id=${proctorerId}`;
}

export const urlLiveScreen = (participantId, folderRand, ts = "") => {
    return `${API_URL_FILE}/imageapi?examid=${getExamId()}&type=livescreen&participantid=${participantId}&ts=${ts}&folderrand=${folderRand}`;
}

export const urlWebCam = (participantId, folderRand, ts = "") => {
    return `${API_URL_FILE}/imageapi?examid=${getExamId()}&type=participantwebcam&participantid=${participantId}&ts=${ts}&folderrand=${folderRand}`;
}

export const urlMobilePairing = (participantId, folderRand, ts = "") => {
    return `${API_URL_FILE}/imageapi?examid=${getExamId()}&type=participantmobilepairing&participantid=${participantId}&ts=${ts}&folderrand=${folderRand}`;
}

export const urlFaceInWebCam = (participantId, folderRand, ts = "") => {
    return `${API_URL_FILE}/imageapi?examid=${getExamId()}&type=participantfaceinwebcam&participantid=${participantId}&ts=${ts}&folderrand=${folderRand}`;
}

export const getVideoQualityOptions = (type, mType = "video/webm;codecs=h264") => {
    // the framerate, resolution, etc is set on getUserMedia, not here
    let q = {
        mimeType: mType,

        audioBitsPerSecond: 128000,
        videoBitsPerSecond: 1024000,
        frameRate: 30
    }

    switch (type) {
        case "64kbps":
            q.audioBitsPerSecond = 16000
            q.videoBitsPerSecond = 32000
            break;
        case "128kbps":
            q.audioBitsPerSecond = 32000
            q.videoBitsPerSecond = 64000
            break;
        case "256kbps":
            q.audioBitsPerSecond = 64000
            q.videoBitsPerSecond = 128000
            break;
        case "384kbps":
            q.audioBitsPerSecond = 64000
            q.videoBitsPerSecond = 192000
            break;
        case "512kbps":
            q.audioBitsPerSecond = 64000
            q.videoBitsPerSecond = 256000
            break;
        case "640kbps":
            q.audioBitsPerSecond = 64000
            q.videoBitsPerSecond = 320000
            break;
        case "768kbps":
            q.audioBitsPerSecond = 64000
            q.videoBitsPerSecond = 384000
            break;
        case "1024kbps":
            q.audioBitsPerSecond = 64000
            q.videoBitsPerSecond = 512000
            break;
        case "1920kbps":
            q.audioBitsPerSecond = 64000
            q.videoBitsPerSecond = 768000
            break;
        case "2048kbps":
            q.audioBitsPerSecond = 64000
            q.videoBitsPerSecond = 1024000
            break;
        default:
            return q;
    }
    return q;
}

export const privateMessageMerge = (id, ts, fromId, message, raw = false) => {
    // get from local storage
    let msg = localStorage.getItem(id);
    if (raw === true) {
        return msg;
    }
    try {
        if (msg === null) {
            msg = [];
        } else {
            msg = JSON.parse(msg);
        }
    } catch (e) {
        msg = [];
    }
    if (message !== "") {
        try {
            msg.push({
                ts: ts,
                from_id: fromId,
                text: message,
                received: {
                    server: false, // received by server
                    receiver: false // received by receiver
                }
            });
        } catch (e) {
            msg = [];
        }
    }
    return msg;
}

export const preparePrivateMessage = (id, fromId, message, raw = false) => {
    // get from local storage
    let msg = localStorage.getItem(id);
    if (raw === true) {
        return msg;
    }
    try {
        if (msg === null) {
            msg = [];
        } else {
            msg = JSON.parse(msg);
        }
    } catch (e) {
        msg = [];
    }
    if (message !== "") {
        try {
            msg.push({
                ts: Date.now(),
                from_id: fromId,
                text: message
            });
        } catch (e) {
            msg = [];
        }
    }
    return msg;
}

export const FACE_DETECTION_THRESHOLD = 0.5;

export const TrimText = (txt, len = 16) => {
    if (txt === undefined) {
        return "";
    }
    return txt.length > len ? txt.substring(0, len - 3) + "..." : txt;
}

export const detectBrowser = () => {
    let userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf('firefox') > -1) {
        return "Firefox";
    } else if (userAgent.indexOf('chrome') > -1) {
        return "Chrome";
    } else if (userAgent.indexOf('safari') > -1) {
        return "Safari";
    } else {
        return "Browser yang digunakan tidak terdeteksi.";
    }
}

export const DISABLE_SCREEN_NUMBER_DETECTION = true

export const getScreenNumber = async () => {
    if (DISABLE_SCREEN_NUMBER_DETECTION) {
        return 1;
    }
    // Safari doesn't support screendetails
    if (detectBrowser() === "Safari" || isInsideSafeExamBrowser()) {
        return 1;
    }
    return window.getScreenDetails()
        .then((d) => {
            return d.screens.length;
        })
        .catch((e) => {
            return -1;
        })
}

export const fetchSaveImageID = async (participantId, folderRand, error_handler) => {
    return fetchJSONMessageFile('save-image-id', {
        'id': participantId,
        'folder-rand': folderRand
    }, error_handler);
}

export const fetchGetAgreement = async (id, error_handler) => {
    return fetchJSONMessageConf("get-agreement", {
        id: id
    }, error_handler);
}

export const isInsideSafeExamBrowser = () => {
    return navigator.userAgent.includes("SafeExamBrowserForAmanin");
}

export const fetchSaveValidationResult = (id, v = {
    green: -1,
    yellow: -1,
    orange: -1,
    pink: -1,
    red: -1,
    value: -1,
    valid: -1,
    always_valid: -1
}, error_handler = null) => {
    return fetchJSONMessageConf("save-validation-result", {
        id: id,
        green: v.green,
        yellow: v.yellow,
        orange: v.orange,
        pink: v.pink,
        red: v.red,
        value: v.value,
        valid: v.valid,
        always_valid: v.always_valid
    }, error_handler);
}

export const fetchConfirmValidationResult = (uuid, error_handler) => {
    return fetchJSONMessageConf("confirm-validation-result", {
        uuid: uuid
    }, error_handler)
}

export const fetchRefreshToken = async (error_handler) => {
    return fetchJSONMessageConf("refresh-token", {
    }, error_handler);
}

export const fetchRefreshTokenPing = async (error_handler) => {
    return fetchJSONMessageConf("refresh-token-ping", {
    }, error_handler);
}

let refreshTokenLastTs = -1;
let refreshTokenPingLastTs = -1;
export const getTokenName = (id = null) => {
    return `token_${id || localStorage.getItem("user_" + localStorage.getItem("role"))}`
}
export const getExamTokenName = (id = null) => {
    return `exam_token_${id || localStorage.getItem("user_" + localStorage.getItem("role"))}`
}
export const getIframeTokenName = (id = null) => {
    return `iframe_token_${id || localStorage.getItem("user_" + localStorage.getItem("role"))}`
}

export const refreshTokenRoutine = () => {
    if (refreshTokenLastTs === -1) {
        refreshTokenLastTs = Date.now();
    }

    if (Date.now() - refreshTokenLastTs > 1800 * 1000) {
        fetchRefreshToken((err) => {
            Err(err);
        }).then((data) => {
            if (data?.status === "success") {
                data = data.result;
                localStorage.setItem(getTokenName(), data.access_token)
                refreshTokenLastTs = Date.now();
            } else {
                Err(`[error] ${data.errorMessage}`);
            }
        })
    }

    if (refreshTokenPingLastTs === -1) {
        refreshTokenPingLastTs = Date.now();
    }

    if (Date.now() - refreshTokenPingLastTs > 60000) {
        fetchRefreshTokenPing((err) => {
            Err(err);
        }).then((data) => {
            if (data?.ok === false) {
                Err(`[error] ${data.errorMessage}`);
                window.location.href = "/";
                return;
            }
            if (data?.status === "success") {
                // data = data.result;
                refreshTokenPingLastTs = Date.now();
            } else {
                Err(`[error] ${data.errorMessage}`);
            }
        })
    }
}

export const fetchSetAction = async (id, action = "", error_handler = null) => {
    return fetchJSONMessageConf("set-action", {
        id: id, action: action
    }, error_handler);
}

export const fetchUpdateValidationStatus = async (data, error_handler) => {
    return fetchUpdateJSONMessageConf("update-validation-status", {
        uuid: data.uuid,
        validation_status: data.validation_status
    }, error_handler);
}


export const fetchUpdateVideoValidationTime = async (data, error_handler) => {
    return fetchUpdateJSONMessageConf("update-video-validation-time", {
        uuid: data.uuid,
        startTs: data.startTs,
        endTs: data.endTs,
    }, error_handler);
}

export const getPhotoFromLocal = (photo) => {
    return photo.includes("photoid_") ? `${API_URL_FILE}/imageapi?type=photofn&fn=${photo.replace("photoid_", "")}` : photo
}

export const fetchVideoServers = (error_handler) => {
    return fetchJSONMessageConf("get-video-server", {
    }, error_handler)
}

export const elementToCanvas = (el) => {
    try {
        let canvas = document.createElement('canvas');
        if (el.videoWidth !== undefined && el.videoHeight !== undefined) {
            canvas.width = el.videoWidth;
            canvas.height = el.videoHeight;
        } else {
            canvas.width = el.width;
            canvas.height = el.height;
        }
        const W = canvas.width
        const H = canvas.height

        if (canvas.width > 854) {
            canvas.width = 854;
            if (el.videoHeight) {
            }
            canvas.height = Math.round(H / W * canvas.width);
            canvas.height = canvas.height % 2 === 0 ? canvas.height : canvas.height + 1;
        }
        // Debug("height = ", canvas.height);
        // if (type === "screen") {
        //     canvas.width = 854;
        //     canvas.height = 480;
        // }

        let ctx = canvas.getContext('2d');
        ctx.drawImage(el, 0, 0, canvas.width, canvas.height);
        return canvas;
    } catch (e) {
        Err(e)
    }
    return null;
}

// export const elementToCanvas = (el) => {
//     let canvas = document.createElement('canvas');

//     Debug("eltocanvas=", el.videoWidth, el.videoHeight);
//     canvas.width = el.videoWidth;
//     canvas.height = el.videoHeight;

//     if (canvas.width > 854) {
//         canvas.width = 854;
//         canvas.height = Math.round(el.videoHeight / el.videoWidth * canvas.width);
//         canvas.height = canvas.height % 2 === 0 ? canvas.height : canvas.height + 1;
//     }
//     Debug("height = ", canvas.height);
//     // if (type === "screen") {
//     //     canvas.width = 854;
//     //     canvas.height = 480;
//     // }

//     let ctx = canvas.getContext('2d');
//     ctx.drawImage(el, 0, 0, canvas.width, canvas.height);
//     return canvas;
// }

export const getIPAddress = async (errorListener) => {
    return fetch('https://api.ipify.org?format=json')
        .then(async (response) => {
            const data = await response.json();
            if (!response) {
                let error = response.status;
                if (data) {
                    error = data.message
                }
                return Promise.reject(error);
            } else {
                return data;
            }
        })
        .catch((error) => {
            errorListener(error)
        });
};

export const getCurrentTime = () => {
    let time = Math.floor(Date.now() / 1000);

    return time;
};

export const fetchCreateCommandChat = async (body, error_handler) => {
    return fetchJSONMessageConf("create-command-chat", {
        command: `/${body.command}`,
        description: body.description,
        response: body.response,
        topic: body.topic,
        access_role: body.role,
    }, error_handler);
}
export const fetchGetCommandChat = async (body, error_handler) => {
    return fetchJSONMessageConf("get-command-chat", {
        page: body.page,
        alphabet: body.alphabet,
        search: body.search,
        limit: body.limit,
        from: "webpage"
    }, error_handler);
}
export const fetchUpdateCommandChat = async (body, error_handler) => {
    return fetchUpdateJSONMessageConf("update-command-chat", {
        id: body.id,
        command: `/${body.command}`,
        description: body.description,
        response: body.response,
        topic: body.topic,
        access_role: body.role,
    }, error_handler);
}
export const fetchDeleteCommandChat = async (body, error_handler) => {
    return fetchDeleteJSONMessageConf("delete-command-chat", {
        id: body.id,
    }, error_handler);
}

export const fetchSaveFaceInCamImage = async (body, error_handler) => {
    return fetchJSONMessageFile("face-in-cam-img", {
        id: body.id,
        img: body.image,
        "folder-rand": body.folder_rand,
    }, error_handler);
}
export const fetchSendIdentityInCamImage = async (body, error_handler) => {
    return fetchJSONMessageFile("identity-in-cam-img", {
        id: body.id,
        img: body.image,
        "folder-rand": body.folder_rand,
    }, error_handler);
}
export const fetchSendFaceInKTPImage = async (body, error_handler) => {
    return fetchJSONMessageFile("face-in-ktp-img", {
        id: body.id,
        img: body.image,
        "folder-rand": body.folder_rand,
    }, error_handler);
}

export const fetchSaveProctorFaceImage = async (body, error_handler) => {
    return fetchJSONMessageFile("proctor-img", {
        profile: {
            id: body.id,
            img: body.image,
        },
    }, error_handler);
}

export const fetchSaveParticipantImage = async (body, error_handler) => {
    return fetchJSONMessageFile("participant-face-img", {
        profile: {
            id: body.id,
            img: body.image,
            ts: body.ts,
            "folder-rand": body.folder_rand,
            unrecorded: body.unrecorded,
            is_warning: body.is_warning,
        },
    }, error_handler);
}
export const fetchSaveScreenImage = async (body, error_handler) => {
    return fetchJSONMessageFile("participant-screen-img", {
        profile: {
            id: body.id,
            img: body.image,
            ts: body.ts,
            "folder-rand": body.folder_rand,
            unrecorded: body.unrecorded,
            is_warning: body.is_warning,
        },
    }, error_handler);
}

export const fetchSaveMobilePairingImage = async (body, error_handler) => {
    return fetchJSONMessageFile("participant-mobile-pairing-img", {
        profile: {
            id: body.id,
            img: body.image,
            ts: body.ts,
            "folder-rand": body.folder_rand,
            unrecorded: body.unrecorded,
            is_warning: body.is_warning,
        },
    }, error_handler);
}

export const fetchSaveFaceVideo = async (body, blob, error_handler) => {
    return fetchJSONMessageBlob("participant-face-video", {
        profile: {
            id: body.id,
            img: body.image,
            ts: body.ts,
            "folder-rand": body.folder_rand,
            unrecorded: body.unrecorded,
            is_warning: body.is_warning,
            record_chunk_duration: 30000
        },
    }, blob, error_handler);
}

export const fetchSaveScreenVideo = async (body, blob, error_handler) => {
    return fetchJSONMessageBlob("participant-screen-video", {
        profile: {
            id: body.id,
            img: body.image,
            ts: body.ts,
            "folder-rand": body.folder_rand,
            unrecorded: body.unrecorded,
            is_warning: body.is_warning,
            record_chunk_duration: 30000
        },
    }, blob, error_handler);
}

export const fetchSaveWarningAudio = async (body, blob, error_handler) => {
    return fetchJSONMessageBlob("participant-warning-audio", {
        profile: {
            id: body.id,
            "folder-rand": body.folder_rand,
            unrecorded: body.unrecorded,
            record_chunk_duration: body.record_chunk_duration
        },
    }, blob, error_handler);
}

export const fetchGetAudio = async (error_handler) => {
    return fetchJSONMessageConf("get-audio", {}, error_handler);
}