import React from "react";
import classes from "./__index__.module.scss";
import AuthContext from "../../store/auth-context";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Lang } from "../../libs/Language";
import Language from "../../components/Language";
import brand from "../../assets/imgs/proktoramaninwhite.png";

import { __Component__ } from "./Component";
import { __Action__ } from "./Action";
import { __Content__ } from "./Content";
import { fetchLogoutSSO, handleScreenChange } from "../../libs/proctor_utils_ext";
import { __TermsAndConditions__ } from "../../components/TermsAndConditions";
import UserProfile from "../../components/UserProfile";
import { fetchGetEventsSetting } from "../../libs/proctor_utils";

// var console = {};
// console.log = function () { };
// console.error = function () { };

// const USE_TELU_SSO = false
const USE_TELU_SSO = false
const DISABLE_LANG = true

class ProktorFirstLogin extends React.Component {
    static contextType = AuthContext;
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    constructor(props) {
        super(props);
        this.inputIDRef = React.createRef();

        this.examId = null;

        if (DISABLE_LANG) {
            localStorage.removeItem("lang");
        }

        this._component = new __Component__(this, classes);
        this._action = new __Action__(this, classes);
        this._content = new __Content__(this, classes);

        this.state = {
            showCreateExamDialog: false,
            showCreateExamModal: false,
            showCreateExamModalMode: "instant", //instant or scheduled

            chosenImage: 0,
            enableJoin: false,
            loginAs: "ems-login",
            showLoginModal: false,
            userProfile: this.context?.profile,
            authURL: null,
            ssoAuth: null,

            showLoading: "",

            showUserManual: "",

            lang: localStorage.getItem("lang") ? localStorage.getItem("lang") : "id",

            desktopView: null
        }
        let host = window.location.hostname
        if (window.location.port !== "") {
            host = `${window.location.hostname}:${window.location.port}`
        }
        this._termsandconditions = new __TermsAndConditions__(this, host);
    }

    componentWillUnmount() {
        this._termsandconditions.unmount();
        this.mobileMediaQuery.removeEventListener('change', this.handleScreenChange); // Hapus listener saat komponen di-unmount
    }

    handleScreenChange = (event) => {
        handleScreenChange(this, event);
    }

    eventCheck(cb) {
        fetchGetEventsSetting().then((data) => {
            if (data.status !== "success") {
                return;
            }
            const { mode, examid } = data.result;
            if (mode === "single") {
                window.location.href = `/id/${examid}`
            } else {
                cb()
            }
        })
    }

    componentDidMount() {
        this.eventCheck(() => {
            this.mobileMediaQuery = window.matchMedia('(max-width: 767px)');
            this.handleScreenChange(this.mobileMediaQuery); // Cek status awal
            this.mobileMediaQuery.addEventListener('change', this.handleScreenChange)

            if (localStorage.getItem("profile")) {
                try {
                    this.setState({
                        userProfile: JSON.parse(atob(localStorage.getItem("profile")))
                    })
                } catch (e) { }
            }

            document.title = "Proktor First - amanin"
            this._termsandconditions.mount();
        })
    }

    generateTopRight() {
        return <div className={classes.container_top_right}>
            {!this.state.userProfile ?
                <div
                    className={[classes.btnOk, classes.login].join(" ")}
                    onClick={() => {
                        this.setState({ showLoginModal: true })
                    }}
                >
                    Login
                </div>
                : <div className={classes.profile}>
                    <UserProfile data={this.state.userProfile}
                        onJoinExam={(examId) => {
                            this._action.joinExam(examId)
                        }}
                        onLogout={() => {
                            this.setState({ showLoading: "Logging Out ...", showLoginModal: false })
                            fetchLogoutSSO(localStorage.getItem("ms_token"), (error) => {
                                console.error("Logout SSO, error=", error)
                            }).then((data) => {
                                console.log("Logout SSO, data=", data);
                            }).finally(() => {
                                this.context.logout()
                                localStorage.removeItem("ms_token");
                                localStorage.removeItem("userdata");
                                localStorage.removeItem("profile");
                                window.location.href = "/"
                                this.setState({ showLoading: "", showLoginModal: false })
                            })
                        }}
                    />
                    {/* <div>
                    {`${this.state.userProfile.name} (${this.state.userProfile.id})`}
                </div>
                <img
                    alt="profile"
                    src={
                        this.state.userProfile.photo !== "" ?
                            this.state.userProfile.photo :
                            'https://amanin.id/photos/bradpitt.jpeg'
                    }
                    height="30px"
                />

                <div className={classes.btnHelp}
                    onClick={() => {
                        this.setState({ showLoading: "Logging Out ...", showLoginModal: false })
                        fetchLogoutSSO(localStorage.getItem("ms_token"), (error) => {
                            console.error("Logout SSO, error=", error)
                        }).then((data) => {
                            console.log("Logout SSO, data=", data);
                        }).finally(() => {
                            this.context.logout()
                            localStorage.removeItem("ms_token");
                            localStorage.removeItem("userdata");
                            localStorage.removeItem("profile");
                            window.location.href = "/"
                            this.setState({ showLoading: "", showLoginModal: false })
                        })
                    }}
                >
                    Logout
                </div> */}
                </div>
            }
            {
                DISABLE_LANG === false && <Language
                    currentLang={this.state.lang}
                    onChangeLang={(v) => {
                        this.setState({ lang: v })
                    }}
                />
            }
        </div>
    }

    renderBrand() {
        const path = `${window.location.hostname}/${window.location.pathname}`;
        if (path.includes("schoters")) {
            return <a href="https://amanin.id">
                <img
                    style={{ backgroundColor: "white", padding: "5px", borderRadius: "5px" }}
                    alt="logo" src={"https://amanin.id/logos/schoters.png"} height={"80px"} /></a>

        } else if (path.includes("bahaso")) {
            return <a href="https://amanin.id">
                <img
                    style={{ backgroundColor: "white", padding: "5px", borderRadius: "5px" }}
                    alt="logo" src={"https://amanin.id/logos/bahaso.png"} height={"80px"} />
            </a>

        }
        return <a href="https://amanin.id"><img alt="logo" src={brand} height={"80px"} /></a>
    }

    render() {
        if (this.state.desktopView === false) {
            return <div className={[classes.container, classes.FadeInPage2s].join(" ")}>
                <div className={classes.container_mobilewarn}>
                    Untuk pengalaman terbaik, silakan akses melalui perangkat desktop atau laptop.
                </div>
            </div>
        }
        this.descList = this.getDescriptionList(this.state.lang);
        let dots = [];
        for (let i in this.descList) {
            dots.push(<div key={i}
                className={this.state.chosenImage === parseInt(i) ? classes.chosen : null}>
            </div>
            )
        }

        return <div className={[classes.container, classes.FadeInPage2s].join(" ")}>
            {this._termsandconditions.render()}
            {this._component.generateBackground()}
            {this.state.showLoading !== "" && <div className={classes.container_modal}>
                {this._component.renderLoading()}
            </div>}
            {this.state.showUserManual !== "" && this._component.renderUserManual()}
            {/* Login */}
            {/* {this.state.showLoginModal && this._component.renderLoginModal()} */}
            {/* Create Exam */}
            {/* {this.state.showCreateExamModal && this._component.renderCreateExamModal()} */}

            <div className={classes.container_top}>
                <div className={classes.container_top_left}>
                    <div className={classes.logo}>
                        {this.renderBrand()}
                    </div>
                </div>
                {/* {this.generateTopRight()} */}
            </div>
            <div className={classes.container_bottom}>
                <div className={classes.container_bottom_l}>
                    <div className={classes.title}>
                        <span>
                            {Lang('Pengawasan Ujian Online Premium.', this.state.lang, {
                                en: 'Premium Online Exam Proctoring.',
                                ch: '高级在线考试监督。',
                                sp: 'Supervisión de Examen en Línea Premium.',
                                jp: 'プレミアムオンライン試験監督',
                                ko: '프리미엄 온라인 시험 감독'
                            })}
                        </span>
                        <span>
                            {Lang('Menuju Ujian Online Berintegritas.', this.state.lang, {
                                en: 'Towards an Online Examination with Integrity.',
                                ch: '走向网络诚信考试。',
                                sp: 'Hacia un Examen en Línea de Integridad.',
                                jp: 'オンライン誠実試験に向かって。',
                                ko: '온라인 무결성 시험을 향하여.'
                            })}
                        </span>
                    </div>
                    <div className={classes.title2}>
                        {Lang(`Kami mendesain layanan pengawasan ujian online ini untuk keamanan dan 
                        kenyamanan peserta dan pengawas, menuju ujian online berintegritas.`,
                            this.state.lang, {
                            en: `We created this online exam monitoring service with the aim of ensuring 
                                the safety and comfort of both participants and supervisors, as we move towards 
                                a more honest and trustworthy online examination.`,
                            ch: `我们设计这个在线考试监控服务，旨在确保参与者和监考人员的安全和舒适，促进更加诚实可信的在线考试。`,
                            sp: `Hemos creado este servicio de supervisión de exámenes en línea con el objetivo de 
                                garantizar la seguridad y comodidad tanto de los participantes como de los supervisores, 
                                mientras avanzamos hacia un examen en línea más honesto y confiable.`,
                            jp: `このオンライン試験監視サービスは、参加者と監督者の安全と快適さを確保し、より誠実で信頼性のあるオンライン試験に向かって進んでいます。`,
                            ko: `우리는 참가자와 감독자 모두의 안전과 편의를 보장하고 믿을 수 있는 온라인 시험을 향해 나아가려고 이 온라인 시험 감시 서비스를 만들었습니다.`

                        })}
                    </div>

                    <div className={classes.button}>
                        {
                            this.state.userProfile?.usertype === "proctorer" && (<>
                                <div className={[classes.btnReload, classes.btn].join(" ")}
                                    onClick={() => {
                                        // if (!this.state.userProfile.terms_and_conditions) {
                                        //     this.setState({ showTerms: true })
                                        //     return;
                                        // }
                                        this.setState({
                                            showCreateExamDialog: true,
                                        })
                                    }}
                                >
                                    Buat Ujian
                                </div>
                                {this._component.generateCreateExamModal()}
                            </>
                            )
                        }
                        <input
                            ref={this.inputIDRef}
                            onChange={(v) => {
                                if (this.inputIDRef.current.value !== "") {
                                    this.setState({ enableJoin: true })

                                } else {
                                    this.setState({ enableJoin: false })
                                }
                            }}
                            onKeyDown={(k) => {
                                if (k.key === "Enter") {
                                    // this._action.joinExam(this.inputIDRef.current.value);
                                    window.location.href = `/id/${this.inputIDRef.current.value}`
                                }
                            }}
                            type="text"
                            placeholder={Lang("Masukkan Kode Ujian", this.state.lang, {
                                en: `Enter The Exam Code`,
                                ch: `输入考试代码`,
                                sp: `Introducir Código de Examen`,
                                jp: `試験コードを入力`,
                                ko: `시험 코드 입력`
                            })}
                        >
                        </input>
                        <span
                            className={this.state.enableJoin ? classes.enable : null}
                            onClick={() => {
                                // this._action.joinExam(this.inputIDRef.current.value)
                                window.location.href = `/id/${this.inputIDRef.current.value}`
                            }}
                        >
                            {
                                // this.state.userProfile?.usertype === 'proctorer' ?
                                //     Lang('Gabung Sebagai Pengawas', this.state.lang, {
                                //         en: `Join as a Proctorer`,
                                //         ch: `加入监管员`,
                                //         sp: `Únete como Supervisor`,
                                //         jp: `監督として参加`,
                                //         ko: `감독자로 참가하세요`
                                //     }) :
                                Lang('Gabung', this.state.lang, {
                                    en: `Join`,
                                    ch: `加入`,
                                    sp: `Participar`,
                                    jp: `参加する`,
                                    ko: `가입하다`
                                })
                            }
                        </span>
                    </div>

                    {this._component.renderHelpUserManual()}
                    <div className={classes.addibutton}>
                        {(this.state.userProfile && this.state.userProfile.usertype === "proctorer") && <div className={classes.btnHelp}
                            onClick={() => {
                                window.open(`https://${window.location.hostname}:4443`)
                            }}
                        >
                            Menuju Manajemen Ujian
                        </div>
                        }
                    </div>
                    <div className={classes.learnmore}>
                        <a href="https://amanin.id/proktorfirstlearnmore">
                            Pelajari Lebih Lanjut tentang Proktor First
                        </a>
                        <span>•</span>
                        <a href="https://amanin.id/termsandconditions">Syarat & Ketentuan Pengguna</a>
                        <span>•</span>
                        <a href="https://amanin.id/privacypolicy">
                            Kebijakan Privasi
                        </a>
                    </div>
                </div>
                <div className={classes.container_bottom_r}>
                    <div className={classes.container_bottom_r_i}>
                        <div className={classes.dir}
                            onClick={() => {
                                this._action.nextPrev("prev");
                            }}
                        >
                            {"<"}
                        </div>
                        <div
                            className={classes.img}
                        >
                            <img
                                src={this.descList[this.state.chosenImage].url}
                                alt="Deskripsi Gambar"
                                style={{
                                    width: '100%',
                                    height: '100%'
                                }}
                            />
                        </div>
                        <div className={classes.dir}
                            onClick={() => {
                                this._action.nextPrev("next");
                            }}
                        >
                            {">"}
                        </div>
                    </div>
                    <div className={classes.container_bottom_r_t}>
                        <div className={classes.title}>
                            {this.descList[this.state.chosenImage].title}
                        </div>
                        <div className={classes.title2}>
                            {this.descList[this.state.chosenImage].content}
                        </div>
                    </div>
                    <div className={classes.container_bottom_r_d}>
                        {dots}
                    </div>
                </div>
            </div>
            {/* <div className={classes.amanin}>
                <AmaninInside />
            </div> */}
        </div>
    }
}

export default withRouter(ProktorFirstLogin);
