import React from "react";
import classes from "./ConnectionBar.module.scss";
import PropTypes from 'prop-types';

class ConnectionBar extends React.Component {
    constructor(props) {
        super(props);

        this.percentage = 0;
        this.calculateResponseTime();
        setInterval(() => {
            this.calculateResponseTime();
        }, 10000);
    }

    calculateResponseTime() {
        const BOTTOM_VALUE = 5000
        const avg = this.props.avgResponseTime;
        let v = BOTTOM_VALUE - avg;
        v = v < 0 ? 0 : v;
        this.percentage = (v / BOTTOM_VALUE) * 100;
    }
    renderDiv(percent, transparent = false) {
        return <div key={percent}>
            <div style={{
                height: `${percent}%`,
                backgroundColor: transparent ? "transparent" : null
            }}>
            </div>
        </div>
    }

    renderDivSmall(percent, transparent = false) {
        return <div key={percent}>
            <div style={{
                height: `100%`,
                backgroundColor: transparent ? "transparent" : null
            }}>
            </div>
        </div>
    }

    renderBig() {
        let el = [];
        [15, 30, 45, 60, 75, 90].forEach((v) => {
            el.push(this.renderDiv(v, this.percentage <= v))
        })
        return <div className={classes.container}>
            <div className={classes.container_bar}>
                {el}
            </div>
            <span>{this.props.avgResponseTime.toFixed(0)}</span>
        </div>
    }

    renderSmall() {
        let el = [];
        [15, 30, 45, 60, 75, 90].forEach((v) => {
            el.push(this.renderDivSmall(v, this.percentage <= v))
        })
        return <div className={[classes.container, classes.container_small].join(" ")}>
            <div className={[classes.container_bar, classes.small].join(" ")}>
                {el}
            </div>
            <div className={classes.textsmall}>
                <span>
                    {(this.props.avgResponseTime / 1000).toFixed(1) >= 100
                        ? Math.round((this.props.avgResponseTime / 1000).toFixed(1))
                        : (this.props.avgResponseTime / 1000).toFixed(1)}
                </span>
                {/* <span>
                    {(this.props.avgResponseTime / 1000).toFixed(1)}
                </span> */}
            </div>
        </div>
    }

    render() {
        if (this.props.type === "small") {
            return this.renderSmall();
        }
        return this.renderBig();
    }
}

ConnectionBar.propTypes = {
    avgResponseTime: PropTypes.number.isRequired,
    type: PropTypes.string,
}

export default ConnectionBar;